<template>
    <div class="product-wrap wlcx-wrap">
        <div class="banner">
            <img class="banner"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/banner.png" alt="">
            <div class="content">
                <div class="label-large"><span>为何各大电商平台选择</span><span class="blue-text">物流云</span></div>
            </div>
        </div>


        <div class="cpgn-wrap" id="cpgn-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    产品核心优势
                </div>
                <div class="title-shadow">
                    Core Advantage
                </div>
            </div>

            <div class="list">
                <div class="item">
                    <div class="title">
                        <p>头部电商服务能力</p>
                        <p>2000+快递查询接口</p>
                    </div>

                    <div class="desc">
                        支持2000+家快递公司物流查询，菜鸟为淘系电商提供物流服务整体解决方案，已经实现调用物流信息原始数据，物流轨迹信息全面和精准，覆盖范围广
                    </div>
                </div>

                <div class="item">
                    <div class="title">
                        <p>低延迟高并发</p>
                        <p>毫秒级响应</p>
                    </div>

                    <div class="desc">
                        阿里云强大的技术服务及运算能力，提供多维度系统稳定性保障，打造稳定高效的物流服务，连续保障了如双11当日超22亿次查询；产品采用高可用架构，物流负载容灾自动切换
                    </div>
                </div>

                <div class="item">
                    <div class="title">
                        <p>贴合业务需求</p>
                        <p>个性化定制</p>
                    </div>

                    <div class="desc">
                        针对项目组建专业的服务团队，销售，产品经理，运营经理，研发技术，售后管家和项目总监，多岗位协同支持项目方案，业务测试和项目运营；面向客户特殊场景提供定制服务，完全耦合业务需求
                    </div>
                </div>

                <div class="item">
                    <div class="title">
                        <p>行业领先技术</p>
                        <p>性价比高</p>
                    </div>

                    <div class="desc">
                        开发者资源丰富，简单快速的接入流程，快速开通，调试方便，提供示例代码，告别漫长开发周期，保障您业务的稳定运行，协助您拥有像淘宝一样的物流能力，建立智能，安全的物流管理体系
                    </div>
                </div>
            </div>
        </div>

        <div class="yycj-wrap" id="cpys-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    客户价值为先
                </div>
                <div class="title-shadow">
                    Customer Value First
                </div>
            </div>

            <div class="card-list">
                <div class="item">
                    <img class="logo"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/jiazhi-1.png"
                        alt="" srcset="">
                    <div class="label">稳定运营保障</div>

                    <div class="wrap">
                        <img class="select-logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/jiazhi-1-selected.png"
                            alt="">
                        <div class="label">稳定运营保障</div>
                        <div class="desc">标准化API接口，系统级方案实施，详细的技术文档， 解放研发团队生产力，让企业精力专注到业务领域中去。</div>
                    </div>
                </div>
                <div class="item">
                    <img class="logo"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/jiazhi-2.png"
                        alt="" srcset="">
                    <div class="label">售后无忧</div>

                    <div class="wrap">
                        <img class="select-logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/jiazhi-2-selected.png"
                            alt="">
                        <div class="label">售后无忧</div>
                        <div class="desc">签约仅是服务的开始，快速响应需求，专属客服与技术实施团队7*24小时支撑，为您轻松解决售后所有难题。</div>
                    </div>
                </div>
                <div class="item">
                    <img class="logo"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/jiazhi-3.png"
                        alt="" srcset="">
                    <div class="label">轻松接入</div>

                    <div class="wrap">
                        <img class="select-logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/jiazhi-3-selected.png"
                            alt="">
                        <div class="label">轻松接入</div>
                        <div class="desc">简单的接入方式，专业技术指导，对接更加方便。丰富的集成接口，快速嵌入企业系统。</div>
                    </div>
                </div>
                <div class="item">
                    <img class="logo"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/jiazhi-4.png"
                        alt="" srcset="">
                    <div class="label">客户成功</div>

                    <div class="wrap">
                        <img class="select-logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/jiazhi-4-selected.png"
                            alt="">
                        <div class="label">客户成功</div>
                        <div class="desc">提供基于客户使用场景的最佳实践方案，加速产品在平台各环节落地，助力平台增长。同时您可享受物流云产品升级迭代免费使用。</div>
                    </div>
                </div>
            </div>

            <div class="num-list">
                <div class="item">
                    <div class="title">
                        <div class="num">60</div>
                        <div class="currency">万+</div>
                    </div>
                    <div class="desc">企业用户共同选择</div>
                </div>

                <div class="item">
                    <div class="title">
                        <div class="num">1600</div>
                        <div class="currency">家+</div>
                    </div>
                    <div class="desc">支持全球2000+物流服务商</div>
                </div>

                <div class="item">
                    <div class="title">
                        <div class="num">5.8</div>
                        <div class="currency">亿+</div>
                    </div>
                    <div class="desc">日均处理数据服务次数</div>
                </div>

                <div class="item">
                    <div class="title">
                        <div class="num">8</div>
                        <div class="currency">年+</div>
                    </div>
                    <div class="desc">多年物流接口开发经验</div>
                </div>

                <div class="item">
                    <div class="title">
                        <div class="num">3000</div>
                        <div class="currency">万+</div>
                    </div>
                    <div class="desc">日均订单数量处理</div>
                </div>

            </div>
        </div>

        <div class="parnter-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    物流合作服务商
                </div>
                <div class="title-shadow">
                    Collab Svc Prov
                </div>
            </div>

            <div class="express-list">
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
            </div>
        </div>


        <!-- 联系我们 -->
        <ContactWrap />
        <!-- <div class="column-wrap contact-wrap">
            <div class="contact-content">
                <div class="left">
                    <div class="label">告诉我们您的需求</div>
                    <div class="desc">我们很乐意听取您的需求，并讨论我们如何帮助。请填写右侧表格，安排电话或讨论，并在您方便的时间会面。</div>
                    <div class="dashborder"></div>
                    <div class="label-2">
                        <p>关于您的预订有任何问题吗？</p>
                        <p>您可以通过以下方式与我们联系：</p>
                    </div>
                    <div class="contact-list">
                        <p>咨询电话：18002581281</p>
                        <p>合作邮箱：liu.guannan@iwhalecloud.com</p>
                        <p>办公地址：中山市小榄镇升平中路10号2座1101-1113房</p>
                    </div>
                </div>

                <CommonForm />
            </div>
        </div> -->
    </div>
</template>

<script>
// import CommonForm from '../../components/CommonForm.vue'
import ContactWrap from '@/components/Contact-wrap.vue';
export default {
    data() {
        return {
            fangshiIndex: 0,
            hexinIndex: 0,
        }
    },
    components: {
        ContactWrap,
    },
}
</script>

<style lang="scss" scoped>
@import '../../static/style/product.common.scss';

.banner {
    .content {
        .label-large {
            width: 350px;
            font-weight: bold;
            font-size: 48px;
            vertical-align: middle;
            line-height: 65px;

            .blue-text {
                color: #0555FF;
                line-height: 65px;
                display: inline-block;
            }
        }
    }
}

.cpgn-wrap {
    padding: 80px 159px 59px 210px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    // flex-grow: 1;

    .list {
        display: flex;
        justify-content: space-between;
        margin-top: 37px;
        width: 100%;
        height: 370px;

        .item {
            padding: 28px 19px 0 18px;
            width: 272px;
            height: 365px;
            box-sizing: border-box;
            background-image: url('https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/hexin.png');
            background-position: bottom;
            background-size: 100% auto;
            background-repeat: no-repeat;

            .title {
                font-size: 22px;
                line-height: 30px;
                color: #131424;
                font-size: 500;
            }

            .desc {
                margin-top: 18px;
                font-size: 16px;
                color: #828282;
                line-height: 26px;
            }
        }
    }

}

.yycj-wrap {
    padding: 49px 149px 53px;
    background-image: url('https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/jiazhi-bg.png');
    background-position: bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-bottom: 200px;


    .card-list {
        background-color: #F2F7FD;
        width: 100%;
        display: flex;
        margin-top: 100px;

        .item {
            width: 25%;
            height: 322px;
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            .logo {
                width: 128px;
                height: 128px;
            }


            .label {
                font-size: 22px;
                margin-top: 25px;
            }

            .wrap {
                display: none;
                background-color: #0555FF;
                border-radius: 16px;
                height: 371px;
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                transform: translateY(-50%);
                // display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .select-logo {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 34px;
                }

                .label {
                    color: #fff;
                }

                .desc {
                    font-size: 16px;
                    color: #fff;
                    line-height: 22px;
                    width: 203px;
                    margin-top: 14px;
                    text-align: center;
                }
            }

            &:hover {
                .wrap {
                    transition: all .4s;
                    display: flex;
                }
            }
        }
    }

    .num-list {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-top: 80px;
        height: 204px;

        .item {
            width: 180px;
            height: 204px;
            box-sizing: border-box;
            padding-left: 30px;
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 204px;
                width: 3px;
                background-image: linear-gradient(180deg, #0555FF 0%, #0c8eff00 100%);
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 3px;
                height: 35px;
                width: 3px;
                background-color: #0555FF;
            }

            .title {
                display: flex;
                align-items: baseline;

                .num {
                    font-size: 36px;
                    color: #02112E;
                    font-weight: bold;
                }

                .currency {
                    font-size: 16px;
                }
            }

            .desc {
                font-size: 15px;
                color: #828282;
                margin-top: 20px;
            }
        }
    }
}

.cpys-wrap {
    padding: 49px 149px 53px;

    display: flex;
    align-items: stretch;

    .left {
        width: 375px;

        .item {
            margin-bottom: 18px;
            width: 375px;
            height: 64px;
            line-height: 64px;
            cursor: pointer;
            padding-left: 26px;
            padding-right: 26px;
            box-sizing: border-box;
            background: #FFFFFF;
            box-shadow: 0 0 12px 0 rgba($color: #000000, $alpha: 0.1);
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon {
                width: 13px;
                height: 22px;
            }
        }

        .item-active {
            background-color: #0555FF;
            color: #fff;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: 60px;

        .step-list {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            position: relative;
            margin-top: 37px;

            &::after {
                content: "";
                width: 90%;
                height: 2px;
                background-color: #E3ECFF;
                position: absolute;
                top: 30px;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
            }

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .logo {
                    width: 50px;
                    height: 50px;
                }

                >p {
                    color: #000;
                    font-size: 14px;
                    font-weight: bold;
                    margin-top: 5px;
                }
            }

            .item-active {
                .logo {
                    transform: scale(1.5);
                    transition: all .2s;
                }

                >p {
                    font-size: 16px;
                    margin-top: 10px;
                    color: #0555FF;
                }
            }
        }

        .desc-list {
            margin-top: 48px;

            >p {
                font-size: 16px;
                line-height: 18px;
                margin-top: 20px;
                color: #000;

            }
        }

        .link-btn {
            font-size: 15px;
            margin-top: 25px;
            color: #003CE2;
            display: flex;
            align-items: center;

            .link-icon {
                width: 15px;
                height: 15px;
                margin-left: 3px;

            }
        }
    }

    .youshi-list {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item {
            box-sizing: border-box;
            width: 368px;
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            box-shadow: 0 0 12px 0 #0000000d;
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            padding: 30px 20px;


            .left {
                .title {
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #02112E;
                }

                .desc {
                    margin-top: 10px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #828282;
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }

            .icon {
                width: 64px;
                height: 64px;
                margin-left: 11px;
            }
        }
    }
}

.parnter-wrap {
    padding: 49px 149px 53px;

    background-image: url('https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/xzwm/hezuo-bg.png');
    background-size: cover;
    background-repeat: no-repeat;

    .express-list {
        display: flex;
        margin: 40px auto 0;
        flex-wrap: wrap;
        justify-content: space-between;

        .express-item {
            width: 257px;
            height: 94px;
            margin-bottom: 35px;

            @for $i from 1 through 16 {
                &:nth-of-type(#{$i}) {
                    background: url(https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/express/express-img-#{$i}.png);
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}
</style>