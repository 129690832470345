<template>
    <div class="container index-container">
        <div class="banner">
            <!-- <img class="banner" src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/banner.png" alt=""> -->
            <img class="banner"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/index/banner.png" alt="">
            <div class="content">
                <div class="title">菜鸟物流解决方案</div>
                <div class="desc">淘系物流产品｜支撑淘系电商，日超22亿次查询｜支持接口定制</div>
                <div class="btn-list">
                    <!-- <a href="javascript:void(0)" class="btn-zixun track-lxwm-el">免费体验</a> -->
                    <span @click="handleRegisterJump" class="btn-zixun">免费体验</span>
                </div>
            </div>
        </div>

        <div class="card">
            <p class="card-title">我们的产品</p>
            <p class="card-sub-title">丰富的产品矩阵，满足您的物流需求</p>

            <div class="product-list">
                <div class="product-item ">
                    <router-link class="wrap" to="/kdcx?tab=2">
                        <div class="title">
                            <img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/proction-icon-blue-1.png"
                                alt="">
                            <img class="icon2"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/proction-icon-white-1.png"
                                alt="">
                            <p>物流查询</p>
                        </div>
                        <div class="context">
                            支持国内外2000多家快递公司，输入运单编号自动识别物流公司，即时返回物流轨迹。
                        </div>

                        <div class="btn">
                            了解详情
                            <img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-right.png"
                                alt="">
                        </div>
                    </router-link>
                </div>
                <div class="product-item ">
                    <router-link class="wrap" to="/kdcx?tab=1">
                        <div class="title">
                            <img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/proction-icon-blue-2.png"
                                alt="">
                            <img class="icon2"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/proction-icon-white-2.png"
                                alt="">
                            <p>订阅</p>
                        </div>
                        <div class="context">
                            汇集国内外2000多家快递公司的物流轨迹数据，以接口形式开放给用户使用，数据内容包括运单号、物流跟踪信息、签收状态等。适用于电商给买家查询物流轨迹、系统内部做物流状态的监控管理、查询物流轨迹详情。
                        </div>


                        <div class="btn">
                            了解详情
                            <img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-right.png"
                                alt="">
                        </div>
                    </router-link>
                </div>
                <div class="product-item ">
                    <router-link class="wrap" to="/dtgj">
                        <div class="title">
                            <img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/proction-icon-blue-3.png"
                                alt="">
                            <img class="icon2"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/proction-icon-white-3.png"
                                alt="">
                            <p>地图轨迹</p>
                        </div>
                        <div class="context">
                            包裹什么时候能到，是每个用户在平台消费后最关心的事情。结合地图功能，可视化的以接口API与嵌入式页面开放给客户使用，支持顺丰、京东，四通一达等100多家主流快递公司在地图上直观的展示包裹的位置和预计送达时间。
                        </div>

                        <div class="btn">
                            了解详情
                            <img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-right.png"
                                alt="">
                        </div>
                    </router-link>
                </div>
                <div class="product-item ">
                    <router-link class="wrap" to="/sxyc?tab=1">
                        <div class="title">
                            <img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/proction-icon-blue-4.png"
                                alt="">
                            <img class="icon2"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/proction-icon-white-4.png"
                                alt="">
                            <p>时效预测（发货前）</p>
                        </div>
                        <div class="context">
                            预估发货时间主要考虑有些场景因不同时间点对揽收波次会有较大影响，为了更精准的预估建议传递预估时间，支持按照单号和cp查询发货前时效预测。
                        </div>

                        <!-- <div class="sub-context">
                            <p>· 在线退货 · 上门取件</p>
                            <p>· 在线支付 · 运费险赔付</p>
                        </div> -->

                        <div class="btn">
                            了解详情
                            <img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-right.png"
                                alt="">
                        </div>
                    </router-link>
                </div>
                <div class="product-item ">
                    <router-link class="wrap" to="/sxyc?tab=2">
                        <div class="title">
                            <img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/proction-icon-blue-5.png"
                                alt="">
                            <img class="icon2"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/proction-icon-white-5.png"
                                alt="">
                            <p>时效预测（发货后）</p>
                        </div>
                        <div class="context">
                            参与发货的时效预估，主要考虑有些场景需要对当前到达位置与目标地点进行预估轨迹和时效测算，支持按照单号和cp查询发货前时效预测。
                        </div>

                        <div class="btn">
                            了解详情
                            <img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-right.png"
                                alt="">
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="card card-2 plan-card">
            <p class="card-title">多行业解决方案，全场景覆盖</p>
            <div class="plan-list">
                <div class="plan-wrap">
                    <div class="item item-1 plan-item">
                        <div class="title">电商行业</div>
                        <div class="sub-title">
                            电商平台物流模块整体解决方案，提供发货服务、物流跟踪、时效配送、在线退货寄件等一体化解决方案，满足多种场景电商服务需求。</div>

                    </div>
                    <div class="item item-2 plan-item">
                        <div class="title">物流查询</div>
                        <div class="sub-title">
                            提供实时物流信息查询和物流信息订阅、推送服务。支持2000+物流公司信息查询，单号订阅，返回物流信息。</div>

                    </div>
                </div>
                <div class="plan-wrap">
                    <div class="item item-3 plan-item">
                        <div class="title">ERP系统商</div>
                        <div class="sub-title">
                            为第三方系统平台入如ERP、WMS、SCM提供物流模块整体解决方案，解决发货及物流跟踪管理难题，让平台拥有像天猫、京东、Amazon等头部电商一样的物流能力。</div>
                    </div>
                    <div class="item item-4 plan-item">
                        <div class="title">退换货</div>
                        <div class="sub-title">为电商平台或自营电商提供全国范围2小时预约上门取件、智能调配服务，提升用户体验。</div>
                    </div>
                </div>
                <div class="plan-wrap">
                    <div class="item item-5 plan-item-l">
                        <div class="title">打单发货</div>
                        <div class="sub-title">
                            提供在线下单，预约上门取件，电子面单服务，提高发货效率。</div>

                    </div>
                    <div class="item plan-item-s plan-item-last">
                        <a href="javascript:void(0)" class="context track-lxwm-el"><img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/plan-contact.png" />更多方案请咨询客服</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <p class="card-title">物流轨迹查询核心优势</p>
            <p class="card-sub-title">用户能查看包裹实时位置，商家能及时处理包裹问题件，平台能监管交易公平公正</p>

            <div class="core-list">
                <div class="core-item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/core-icon-1.png"
                        alt="">
                    <div class="title">头部电商服务能力2000+快递查询接口</div>
                    <div class="context">支持2000+家快递公司物流查询，菜鸟为淘系电商提供物流服务整体解决方案，已经实现调用物流信息原始数据，物流轨迹信息全面和精准，覆盖范围广</div>
                </div>
                <div class="core-item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/core-icon-2.png"
                        alt="">
                    <div class="title">低延迟高并发毫秒级响应</div>
                    <div class="context">
                        阿里云强大的技术服务及运算能力，提供多维度系统稳定性保障，打造稳定高效的物流服务，连续保障了如双11当日超22亿次查询；产品采用高可用架构，物流负载容灾自动切换</div>
                </div>
                <div class="core-item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/core-icon-3.png"
                        alt="">
                    <div class="title">贴合业务需求个性化定制</div>
                    <div class="context">
                        针对项目组建专业的服务团队，销售，产品经理，运营经理，研发技术，售后管家和项目总监，多岗位协同支持项目方案，业务测试和项目运营；面向客户特殊场景提供定制服务，完全耦合业务需求</div>
                </div>
                <div class="core-item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/core-icon-4.png"
                        alt="">
                    <div class="title">行业领先技术性价比高</div>
                    <div class="context">
                        开发者资源丰富，简单快速的接入流程，快速开通，调试方便，提供示例代码，告别漫长开发周期，保障您业务的稳定运行，协助您拥有像淘宝一样的物流能力，建立智能，安全的物流管理体系</div>
                </div>
            </div>
        </div>

        <div class="card card-2">
            <p class="card-title">物流合作服务商</p>
            <div class="express-list">
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
                <div class="express-item"></div>
            </div>
        </div>

        <div class="card partner-card">
            <img class="left"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/partner-left-img.png" />
            <div class="right">
                <p class="title">合作伙伴</p>
                <div class="partner-list">
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                    <div class="partner-item"></div>
                </div>
            </div>
        </div>

        <div class="card contact-card">
            <p class="title">联系我们</p>

            <div class="list">
                <div class="item">
                    <p class="name">办公地址</p>
                    <p class="value">中山市小榄镇升平中路10号2座1101-1113房</p>
                </div>
                <div class="item">
                    <p class="name">咨询电话</p>
                    <p class="value">15645112352</p>
                </div>
                <div class="item">
                    <p class="name">商务合作邮箱</p>
                    <p class="value">1721003310@qq.com</p>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.container {
    // min-width: 1497px;
    overflow-x: hidden;
    margin: 0 auto;
    max-width: 100vw;

    .banner {
        width: 100%;
        height: auto;
        position: relative;
        text-align: left;
        max-width: 100vw;

        .content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 149px;

            .title {
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 36px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 43.88px;
            }

            .desc {
                margin-top: 12px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 30px;
                width: 600px;
            }

            .btn-list {
                margin-top: 40px;
                display: flex;
                align-items: center;

                .btn-zixun {
                    width: 105px;
                    height: 36px;
                    line-height: 36px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #0555FF;
                    letter-spacing: 0;
                    text-align: center;
                    margin-right: 14px;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }

    .card {
        padding: 63px 0 84px;
        box-sizing: border-box;
        width: 100%;
        max-width: 100vw;
        overflow: hidden;

        .card-title {
            font-size: 36px;
            text-align: center;
            color: #000;
            line-height: 43px;
            font-weight: bold;
            font-style: oblique;
            margin-bottom: 22px;
        }

        .card-sub-title {
            font-size: 14px;
            line-height: 20px;
            color: #5D5F67;
            text-align: center;
        }

        .product-list {
            display: flex;
            margin: 43px auto 0;
            justify-content: space-between;
            // padding: 0 118px;
            width: 1204px;

            .product-item {
                width: 222.44px;
                height: 551px;
                background-color: #999;
                position: relative;
                text-decoration: none;

                .wrap {
                    text-decoration: none;
                    color: #021943;
                    width: 201px;
                    height: 260px;
                    position: absolute;
                    left: 11px;
                    bottom: 15px;
                    background-color: rgba($color: #fff, $alpha: .8);
                    border-radius: 6px;
                    padding: 20px 18px;
                    box-sizing: border-box;
                    transition: all .3s;
                    cursor: pointer;


                    .title {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;

                        .icon {
                            width: 28px;
                            height: 28px;
                            object-fit: contain;
                        }

                        .icon2 {
                            display: none;
                            width: 28px;
                            height: 28px;
                            object-fit: contain;
                        }

                        p {
                            font-size: 20px;
                            line-height: 28px;
                            font-weight: 500;
                            margin-left: 4px;
                        }
                    }

                    .context {
                        font-size: 14px;
                        color: #021943;
                        line-height: 20px;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 5;
                    }

                    .sub-context {
                        display: none;
                        color: #ffffff;
                        font-size: 14px;
                        margin-top: 19px;

                        p {
                            margin-bottom: 11px;
                            font-weight: 400;
                            font-family: PingFangSC;
                        }
                    }

                    .btn {
                        width: 90px;
                        height: 36px;
                        background-color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        color: #041833;
                        font-family: PingFangSC-Regular;
                        font-weight: 400;
                        position: absolute;
                        left: 18px;
                        bottom: 21px;
                        border-radius: 3px;

                        .icon {
                            width: 14px;
                            height: 14px;
                            object-fit: contain;
                        }
                    }

                    &:hover {
                        background-image: linear-gradient(177deg, rgba(#0051FF, .8) 0%, rgba(#0050FA, .8) 100%);
                        height: 343px;

                        .title,
                        .context {
                            color: #fff;
                            -webkit-line-clamp: 10;
                        }

                        .title {
                            .icon {
                                display: none;
                            }

                            .icon2 {
                                display: block;
                            }
                        }

                        .sub-context {
                            display: block;
                        }
                    }
                }
            }

            @for $i from 1 through 5 {
                .product-item:nth-of-type(#{$i}) {
                    background: url(https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product-bg-#{$i}.png);
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }

        .plan-list {
            width: 1137px;
            display: flex;
            justify-content: space-between;
            margin: 81px auto 0;

            .plan-wrap {
                display: flex;
                flex-direction: column;
                width: 366.81px;
                height: 502px;
                justify-content: space-between;

                .item {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding-left: 21px;
                    padding-bottom: 30px;
                    box-sizing: border-box;
                    transition: all .3s;

                    .title {
                        width: 115px;
                        height: 47px;
                        padding: 0 12px;
                        line-height: 47px;
                        border-radius: 8px;
                        text-align: center;
                        background-color: rgba($color: #000000, $alpha: .3);
                        font-size: 23px;
                        color: #fff;
                        font-family: PingFangSC;
                        box-sizing: content-box;
                    }

                    .sub-title {
                        width: 318px;
                        margin-top: 20px;
                        font-family: PingFangSC-Regular;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        letter-spacing: 0;
                        text-align: justify;

                        height: 0;
                        overflow: hidden;
                        transition: all .3s;
                    }

                    &:hover {
                        .sub-title {
                            transition: all .3s;
                            height: 100px;
                        }
                    }
                }

                @for $i from 1 through 5 {
                    .item-#{$i} {
                        background: url(https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/plan-item-bg-#{$i}.png);
                        background-size: auto 100%;
                        background-repeat: no-repeat;
                    }
                }

                // .item:hover {
                //     background-position: center;
                //     background-size: auto 130%;
                // }

                .plan-item {
                    width: 366.81px;
                    height: 242px;
                    border-radius: 8px;
                    background-color: #e8e8e8;
                }

                .plan-item-l {
                    width: 366.81px;
                    height: 341px;
                    border-radius: 8px;
                    background-color: #e8e8e8;
                }

                .plan-item-s {
                    width: 366.81px;
                    height: 143px;
                    border-radius: 8px;
                    background-color: #e8e8e8;
                }

                .plan-item-last {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    background-color: #031845;
                    font-size: 18px;
                    color: #fff;

                    .context {
                        display: flex;
                        align-items: center;
                        font-family: PingFangSC-Regular;
                        font-weight: 400;

                        color: #fff;
                        text-decoration: none;
                    }

                    .icon {
                        width: 20px;
                        height: 20px;
                        margin-right: 4px;
                    }
                }
            }
        }

        .core-list {
            width: 1137px;
            display: flex;
            justify-content: space-between;
            margin: 81px auto 0;

            .core-item {
                width: 272px;
                height: 433px;
                background: #FFFFFF;
                border: 2px solid #E9EAED;
                box-shadow: 0 0 16px 0 #000c250a;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 20px;

                .icon {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }

                .title {
                    width: 218px;
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 24px;
                    color: #000000;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 30px;
                    margin-bottom: 43px;
                    margin-top: 20px;
                }

                .context {
                    width: 213px;
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 16px;
                    color: #7E8085;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 26px;
                }
            }
        }

        .express-list {
            width: 578px;
            height: 246px;
            display: flex;
            margin: 40px auto 0;
            flex-wrap: wrap;
            justify-content: space-between;

            .express-item {
                width: 131px;
                height: 48px;

                @for $i from 1 through 16 {
                    &:nth-of-type(#{$i}) {
                        background: url(https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/express/express-img-#{$i}.png);
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }

    .plan-card {
        background-image: url(https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/plan-bg.png);
        background-size: cover;
    }

    .card-2 {
        background-color: #f5f5f5;
    }

    .partner-card {
        padding: 0;
        display: flex;

        .left {
            width: 743px;
            height: 514px;
        }

        .right {
            background-color: #144CAB;
            flex-grow: 1;
            padding-top: 71px;
            padding-left: 84px;
            padding-right: 157px;

            .title {
                font-family: DingTalk-JinBuTi;
                font-weight: JinBuTi;
                font-size: 24px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 55px;
            }

            .partner-list {
                width: 433px;
                display: flex;
                // margin: 40px auto 0;
                flex-wrap: wrap;
                justify-content: space-between;

                .partner-item {
                    width: 131px;
                    height: 48px;
                    margin-bottom: 20px;
                    border-radius: 4px;

                    @for $i from 1 through 12 {
                        &:nth-of-type(#{$i}) {
                            background: url(https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/partner/partner-img-#{$i}.png);
                            background-size: contain;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
    }

    .contact-card {
        padding-top: 95px;
        padding-left: 149px;

        .title {
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            letter-spacing: 1.5px;
            line-height: 22px;
            margin-bottom: 88px;
            text-align: left;
        }

        .list {
            display: flex;

            .item {
                margin-right: 104px;

                .name {
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #7E8085;
                    letter-spacing: 1.5px;
                    line-height: 22px;
                    margin-bottom: 7px;
                }

                .value {
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 18px;
                    color: #000000;
                    letter-spacing: 0;
                    line-height: 30px;
                }
            }
        }
    }
}
</style>