<template>
    <div class="lxvm-wrap">
        <div class="title">联系我们</div>

        <div class="tab-list">
            <div class="item">
                <img class="icon"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/lxwm/icon-position.png" />
                <div class="right">
                    <div class="top">办公地址</div>
                    <div class="bottom">中山市小榄镇升平中路10号2座1101-1113房</div>
                </div>
            </div>

            <div class="item">
                <img class="icon"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/lxwm/icon-email.png" />
                <div class="right">
                    <div class="top">商务合作邮箱</div>
                    <div class="bottom">1721003310@qq.com</div>
                </div>
            </div>

            <div class="item">
                <img class="icon"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/lxwm/icon-phone.png" />
                <div class="right">
                    <div class="top">咨询电话</div>
                    <div class="bottom">15645112352</div>
                </div>
            </div>
        </div>

        <div class="card">
            <!-- <div class="title">咨询专家</div> -->
            <CommonForm2 />

            <!-- <div class="label">您的称呼</div>
            <input class="input-comp" v-model="userName" />
            <div class="label">您的电话</div>
            <input class="input-comp" v-model="userPhone" />
            <div class="label">公司名称</div>
            <input class="input-comp" v-model="companyName" />
            <div class="label">您的职位</div>
            <input class="input-comp" v-model="userJob" />

            <div class="submit-btn">提交</div> -->
        </div>

       

        <img class="banner"
            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/lxwm/banner1.png" />

        <ToastComp ref="toastRef" />
    </div>
</template>
<script>
import ToastComp from '../../components/ToastComp.vue';
import CommonForm2 from '../../components/CommonForm-large.vue';
import { saveContact } from '../../api/index.js'
export default {
    components: {
        ToastComp,
        CommonForm2
    },
    data() {
        return {
            userName: '',
            userPhone: '',
            companyName: '',
            userJob: '',
        }
    },
    methods: {
        handleSubmit() {
            const params = {
                userName: this.userName,
                userPhone: this.userPhone,
                companyName: this.companyName,
                userJob: this.userJob,
            }
            console.log(params)

            saveContact(params).then(res => {
                if (res.code == 500) {
                    this.$refs.toastRef.show(res.msg)
                } else {
                    this.$refs.toastRef.show("提交成功")
                    this.userName = '';
                    this.userPhone = '';
                    this.companyName = '';
                    this.userJob = '';
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>    