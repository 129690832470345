<template>
    <div class="product-wrap wlcx-wrap">
        <div class="banner">
            <img class="banner"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjdzk/banner-1.png"
                alt="">
            <div class="content">
                <div class="title">国际地址库</div>
                <div class="desc">对海外地址数据的识别-解析-补全等机制，将文本或图片识别信息后解析为详细地址信息。</div>
                <div class="btn-list">
                    <span @click="handleRegisterJump" class="btn-zixun">免费体验</span>
                    <span @click="handlePriceJump" class="btn-tiyan">查看价格</span>
                </div>
            </div>
        </div>

        <div class="nav-wrap">
            <div class="item nav-item">国际地址库 <img class="icon"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-down.png"
                    alt="">
            </div>
            <router-link to="#cpgn-wrap" class="item item-active">核心功能</router-link>
            <router-link to="#yycj-wrap" class="item">应用场景</router-link>
            <router-link to="#jrlc-wrap" class="item">接入流程</router-link>
            <!-- <div class="item">接入流程</div> -->
        </div>

        <div class="cpgn-wrap" id="cpgn-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    核心功能
                </div>
                <div class="title-shadow">
                    functional
                </div>
            </div>

            <div class="content">
                <div class="left">
                    <div class="item">
                        <div class="info">
                            <div class="title">地址清洗</div>
                            <div class="desc">数据官方权威，行政区划纠错、补全。</div>
                        </div>
                        <img class="logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjdzk/hexin-1.png"
                            alt="">
                    </div>
                    <div class="item">
                        <div class="info">
                            <div class="title">智能解析</div>
                            <div class="desc">智能算法，精准拆分所需数据。</div>
                        </div>
                        <img class="logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjdzk/hexin-2.png"
                            alt="">
                    </div>
                    <div class="item">
                        <div class="info">
                            <div class="title">图片解析</div>
                            <div class="desc">智能算法，精准拆分所需数据。</div>
                        </div>
                        <img class="logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjdzk/hexin-3.png"
                            alt="">
                    </div>
                </div>
                <div class="right">
                    <img class="phone"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjdzk/phone-1.png"
                        alt="">
                    <img class="phone phone-2"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjdzk/phone-2.png"
                        alt="">
                </div>

            </div>

        </div>


        <div class="yycj-wrap" id="yycj-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    应用场景
                </div>
                <div class="title-shadow">
                    scenario
                </div>
            </div>

            <div class="changjing-list">
                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjdzk/changjing-1.png"
                            alt="">
                        <div class="title">跨境发货平台</div>
                        <div class="sub-title">优化处理用户提交的地址数据，场景自动化</div>
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjdzk/changjing-2.png"
                            alt="">
                        <div class="title">跨境寄件平台</div>
                        <div class="sub-title">结构化解析文本内容，提升用户寄件体验及时效</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column-wrap jrlc-wrap" id="jrlc-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    接入流程
                </div>
                <div class="title-shadow">
                    pick-up
                </div>
            </div>

            <div class="liucheng-list">
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-1.png"
                        alt="">
                    <div class="title">账号注册</div>
                    <div class="desc">点击「免费注册」输入手机号和验证码完成注册</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-2.png"
                        alt="">
                    <div class="title">开通服务</div>
                    <div class="desc">开通相关的功能服务</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-3.png"
                        alt="">
                    <div class="title">开发和联调</div>
                    <div class="desc">获取AppKey/AppSecret进行API调用</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-4.png"
                        alt="">
                    <div class="title">开始使用</div>
                    <div class="desc">开始使用浩鲸物流的功能</div>
                </div>

                <div class="arrow-list">
                    <div class="link-arrow link-arrow-1"></div>
                    <div class="link-arrow link-arrow-2"></div>
                    <div class="link-arrow link-arrow-3"></div>
                </div>

            </div>
            <a href="javascript:void(0)" class="contact-btn track-lxwm-el">联系我们</a>
        </div>

        <MoreItem />

        <!-- 联系我们 -->
        <div class="column-wrap contact-wrap">
            <div class="contact-content">
                <div class="left">
                    <div class="label">告诉我们您的需求</div>
                    <div class="desc">我们很乐意听取您的需求，并讨论我们如何帮助。请填写右侧表格，安排电话或讨论，并在您方便的时间会面。</div>
                    <div class="dashborder"></div>
                    <div class="label-2">
                        <p>关于您的预订有任何问题吗？</p>
                        <p>您可以通过以下方式与我们联系：</p>
                    </div>
                    <div class="contact-list">
                        <p>咨询电话：15645112352</p>
                        <!-- <p>合作邮箱：1721003310@qq.com</p> -->
                        <p>办公地址：中山市小榄镇升平中路10号2座1101-1113房</p>
                    </div>
                </div>

                <CommonForm />
            </div>
        </div>
    </div>
</template>

<script>
import CommonForm from '../../components/CommonForm.vue'
import MoreItem from '../../components/MoreItem.vue'
export default {
    components: {
        CommonForm,
        MoreItem
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
@import '../../static/style/product.common.scss';

.cpgn-wrap {
    padding: 80px 159px 59px 210px;
    box-sizing: border-box;

    .content {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;


        .left {
            .item {
                margin-bottom: 20px;
                width: 544px;
                height: 138px;
                box-sizing: border-box;
                align-items: center;
                background: #FFFFFF;
                padding: 0 22px;
                box-shadow: 0 0 12px 0 #0000000d;
                border-radius: 12px;
                display:flex;
                justify-content: space-between;

                .info {
                    .title {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }
                    .desc {
                        font-size: 12px;
                        color: #828282;
                    }
                }
                .logo {
                    width: 109px;
                    height: auto;
                }
            }
        }
        .right {
            .phone {
                width: 224px;
            }
            .phone-2 {
                margin-left: 20px;
            }
        }
    }
}

.yycj-wrap {
    padding: 49px 149px 53px;

    .changjing-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .changjing-item {
            width: 559px;
            border-radius: 12px;
            overflow: hidden;
            position: relative;

            &::after {
                content: "";
                display: block;
                background-image: linear-gradient(180deg, #05367800 0%, #042e6840 40%, #020d43c4 100%);
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
            }

            .top {
                height: 222px;
                position: relative;

                >img {
                    width: 100%;
                    height: 100%;
                }

                .title {
                    height: 28px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    position: absolute;
                    top: 149px;
                    left: 34px;
                    z-index: 10;
                }

                .sub-title {
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    position: absolute;
                    top: 180px;
                    left: 34px;
                    z-index: 10;
                }
            }
        }
    }
}

.cpys-wrap {

    .youshi-list {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item {
            box-sizing: border-box;
            width: 368px;
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            box-shadow: 0 0 12px 0 #0000000d;
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            padding: 30px 20px;

            .left {
                .title {
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #02112E;
                }

                .desc {
                    margin-top: 10px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #828282;
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }

            .icon {
                width: 64px;
                height: 64px;
                margin-left: 11px;
            }
        }
    }
}

.yhjs-wrap {
    background: #F7F9FC;

    .juese-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .item {
            width: 368px;
            background: #FFFFFF;
            box-shadow: 0 0 8px 0 #00000005;
            border-radius: 12px;
            padding: 38px 29px;
            box-sizing: border-box;
            transition: all .3s;

            &:hover {

                .title,
                .desc,
                .link {
                    color: #ffffff !important;
                }

                background-color: #003CE2 !important;
            }

            .icon {
                width: 63px;
                height: 62px;
            }

            .icon-w {
                width: 63px;
                height: 62px;
                display: none;
            }

            &:hover {
                .icon {
                    display: none;
                }

                .icon-w {
                    display: block;
                }
            }

            .title {
                margin-top: 34px;
                line-height: 42px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 30px;
                color: #02112E;
                letter-spacing: 0;
            }

            .desc {
                margin-top: 17px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 16px;
                color: #131424;
                letter-spacing: 0;
                line-height: 24px;
            }

            .link {
                margin-top: 20px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 15px;
                color: #003CE2;
                letter-spacing: 0;
                display: flex;
                align-items: center;

                .link-icon {
                    margin-left: 3px;
                    width: 15px;
                    height: 15px;
                }

                .link-icon-w {
                    display: none;
                    margin-left: 3px;
                    width: 15px;
                    height: 15px;
                }
            }

            &:hover {
                .link-icon {
                    display: none;
                }

                .link-icon-w {
                    display: block;
                }
            }
        }
    }
}

.jrlc-wrap {

    .liucheng-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        position: relative;

        .arrow-list {
            position: absolute;
            top: 53px;
            left: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 265px;
            box-sizing: border-box;
            width: 100%;

            .link-arrow {
                display: flex;
                width: 48px;
                height: 48px;
                background: #FFFFFF;
                border-radius: 50%;
                // background-image: url('../../images/product/wlcx/link-right.png');
                background-position: center;
                background-size: 26px 26px;
                background-repeat: no-repeat;
                box-shadow: 0 0 12px 0 #0000000d;
            }
        }

        .item {
            width: 277px;
            background-image: linear-gradient(180deg, #FBFCFD 0%, #F6F7FA 100%);
            border-radius: 12px;

            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 49px 0 38px;

            .icon {
                width: 80px;
                height: 78px;
            }

            .title {
                margin-top: 33px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 20px;
                color: #02112E;
                letter-spacing: 0;
                text-align: center;
            }

            .desc {
                margin-top: 22px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #131424;
                letter-spacing: 0;
                text-align: center;
                line-height: 20px;
                width: 227px;
                height: 40px;
            }
        }
    }

    .contact-btn {
        margin: 50px auto 0;
        width: 201px;
        height: 50px;
        background: #003CE2;
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        border-radius: 4px;
        display: block;
        color: #fff;
        box-shadow: 0 0 12px 0 #003CE20d;
    }
}
</style>